<template>
  <section id="Teses">
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb></Breadcrumb>
    </div>

    <div class="jumbotron">
      <div class="container-teses d-flex">
        <!-------LINHA 1------->
        <div class="tese tese-blue" @click="$router.push(`/teses/inss`)">
          <div class="btn-acessar">Acessar</div>
          INSS
        </div>

        <div class="tese tese-green" @click="$router.push(`/teses/insumos`)">
          <div class="btn-acessar">Acessar</div>
          PIS/COFINS INSUMOS
        </div>

        <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          ICMS
        </div>

        <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          IPI
        </div>

        <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          REST PIS E COFINS
        </div>

        <!-------LINHA 2------->
        <div
          class="tese tese-green"
          @click="$router.push(`/teses/restituicao`)"
        >
          <div class="btn-acessar">Acessar</div>
          RESTITUIÇÃO
        </div>

        <div class="tese tese-blue" @click="$router.push(`/teses/exclusao`)">
          <div class="btn-acessar">Acessar</div>
          PIS/COFINS EXCLUSÃO
        </div>

        <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          CREDITOS NÃO ALOCADOS
        </div>

        <!-- <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          OUTRAS ENTIDADES
        </div> -->
        <div class="tese tese-blue" @click="$router.push(`/convertInCsv`)">
          <div class="btn-acessar">Acessar</div>
          CSV Primeira análise
        </div>

        <div
          class="tese tese-green"
          @click="$router.push(`/teses/compensacao`)"
        >
          <div class="btn-acessar">Acessar</div>
          COMPENSAÇÂO
        </div>

        <!-- <div class="tese tese-gray">
          <div class="btn-acessar">Em Breve</div>
          COMPENSAÇÂO
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb.vue";

export default {
  name: "Home",

  components: {
    Breadcrumb,
  },
};
</script>

<style>
#Teses {
  min-height: calc(100vh - 130px);
}
.container-teses {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tese {
  flex: 1 0 15%;
  display: flex;
  flex-direction: column-reverse;

  height: 22vh;
  margin: 2%;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  cursor: pointer;

  font-size: 28px;
  font-weight: 700;
  color: white;
  text-align: center;
}

.btn-acessar {
  transition: 0.5s;

  background: white;
  padding: 6px 0px;
  border-radius: 0px 0px 5px 5px;
  width: 100%;

  font-size: 18px;
  font-weight: 400px;
  color: gray;
}

.tese-green {
  transition: 0.5s;
  background: rgba(43, 182, 115, 0.85);
}
.tese-green:hover {
  font-size: 30px;
  background: #2bb673;
}

.tese-blue {
  transition: 0.5s;
  background: rgba(48, 48, 132, 0.85);
}
.tese-blue:hover {
  font-size: 30px;
  background: #303086;
}

.tese-gray {
  transition: 0.5s;
  background: #e3e3e3;
}
.tese-gray:hover {
  background: #ffc4ca;
}
.tese-gray:hover .btn-acessar {
  color: #e82134;
}
</style>
